<template>
  
  <v-progress-linear
    :color="getType()"
    :value="getPercentage()"
  ></v-progress-linear>

</template>

<script>

export default {
  name: 'chronometer',
  props: ['time','redirect'],
  data() {
    return {
      totalSeconds: 0,
      seconds: 0,
      interval: false,
      isPaused: false,
      initOnFinishPause: false
    }
  },
  beforeMount: function(){
    let that = this
    this.$root.$on('onHelp',function(){
      that.isPaused = true
    })
    this.$root.$on('offHelp',function(){
      that.isPaused = false
      if (that.initOnFinishPause){
        that.initChronometer(that.initOnFinishPause.time)
      }
    })
  },
  methods: {
    initChronometer: function(time){

      if (this.isPaused){
        this.initOnFinishPause = {
          time
        }
        return
      }
      this.initOnFinishPause = false

      if (this.interval){
        return
      }
      this.totalSeconds = time ? time + 3 : this.time + 3
      let that = this

      this.interval = setInterval(function() {
        if (that.isPaused) {
          return
        }
        that.seconds = that.seconds + 0.1

        if (that.seconds >= that.totalSeconds) {
          clearInterval(that.interval)
          if (that.redirect){
            that.$store.state.currentRoute = '/'+that.redirect
            that.$router.push(that.redirect)
          }
          that.$root.$emit('onFinishChronometer')
        }

      }, 100);
    },
    getPercentage: function(){
      return this.seconds * 100 / this.totalSeconds
    },
    getType: function(){
      return this.getPercentage() > 90 ? 'success' : 'warning'
    }
  },
  beforeDestroy(){
    this.$root.$off('onHelp')
    this.$root.$off('offHelp')
    if (this.interval){
      clearInterval(this.interval)
    }
  }
}
</script>

<style>

</style>
