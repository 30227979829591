<template>
  
<v-content>
    <message/>
  <v-container grid-list-md>

    <v-btn
      color="primary"
      block
      v-show="!$store.state.fromStart"
      @click="init()"
    >
      CONTINUAR
    </v-btn>

    <v-layout column wrap align-center justify-center v-show="$store.state.fromStart">

      <v-sheet height="40">
        <v-layout align-center justify-center row fill-height class="mb-2" >
          <v-chip class="ml-1"  v-for="sentence in sentences" :key="sentence.id" label :color="sentence.color" :class="sentence.chipClass">
              <v-icon :class="sentence.class" left style="" >mdi-fire</v-icon>
              {{sentence.text}}
          </v-chip>
        </v-layout>
      </v-sheet>
      
      <audio-basic ref="audioBasic"/>
      <chronometer ref="chronometer" :time="9"/>
      <captions-viewer ref="captionsViewer" class="mt-1"/>

      <v-row justify-content="center" mt-2 align-content="center" style="width: 100%;">

        <v-col cols="9" sm="6" offset-sm="2" md="5" offset-md="3">
          <v-textarea
            rows="3"
            full-width
            ref="textCaptions"
            autocapitalize="off"
            autocomplete="off"
            autocorrect="off"
            spellcheck="false"
            no-resize
            solo
            v-model="captions"
            @keyup.enter.native="sendCaptions"
            :disabled="!$store.state.fromStart"
          ></v-textarea>
        </v-col>

        <v-col cols="3" sm="2">
          <v-btn @click="sendCaptions" :disabled="correcting || !$store.state.fromStart" :loading="correcting" fab dark large color="info">
            <v-icon dark>mdi-send</v-icon>
          </v-btn>
        </v-col>

      </v-row>

    </v-layout>

  </v-container>
</v-content>

</template>

<script>

import message from '../appComponents/Message.vue'
import audioBasic from './AudioBasic.vue'
import checkToken from '../../plugins/checkToken'
import chronometer from '../chronometer/Chronometer.vue'
import captionsViewer from './CaptionsViewer.vue'

export default {
  name: 'PlayVerse',
  components: {
    message,
    'audio-basic':audioBasic,
    chronometer,
    'captions-viewer' : captionsViewer
  },
  data() {
    return {
      correcting: false,
      captions: '',
      calculatedCaptions: [],
      initChronometer : false,
      sentences: [],
      lastIdSentence : 1,
      timeouts : [],
      countSentences : 0,
      disableInitFocus: false
    }
  },
  beforeMount(){
    let that = this
    this.$root.$on('onFinishAudio',function(){
      if (!that.initChronometer){
        that.$refs.chronometer.initChronometer()
      }
    })
    this.$root.$on('onHelp',function(){
      that.disableInitFocus = true
    })
    this.$root.$on('offHelp',function(){
      that.$refs.textCaptions.focus()
    })
    this.$store.state.oldIncreaseWordsToday = this.$store.state.increaseWordsToday
  },
  methods: {
    init : function(){
      this.$root.$emit('onInitPlayVerse')
      window.scroll(0, 0)
    },
    initAudio : function(initMoment,finishMoment,status,resource,calculatedCaptions,initChronometer){
      this.initChronometer = initChronometer
      if (this.initChronometer) {
        this.$refs.chronometer.initChronometer(finishMoment)
      }
      this.$refs.audioBasic.init(initMoment,finishMoment,status,resource)
      if (calculatedCaptions.length > 1){
        this.$refs.captionsViewer.initCaptions(calculatedCaptions)
        this.notifyCorrection()
      } else {
        this.viewCaptions(calculatedCaptions)
      }
      if (!this.disableInitFocus){
        this.$refs.textCaptions.focus()
      }
      window.scroll(0, 0)
      setTimeout(function(){window.scroll(0, 0)},150)
    },
    viewCaptions: function(calculatedCaptions){
      this.$refs.captionsViewer.showInitialText(calculatedCaptions)
      window.scroll(0, 0)
    },
    notifyCorrection: function(id,words){
      if (this.countSentences > 0){
        this.countSentences--
      }
      for (let i in this.sentences){
        if (this.sentences[i].id === id) {
          this.sentences[i].class = ''
          this.sentences[i].color = words > 0 ? 'success' : 'error'
          this.sentences[i].chipClass = 'shakeAnimation'
          this.sentences[i].text = '+ ' + words
          let that = this
          this.timeouts.push(setTimeout(function(){
            for (let k in that.sentences) {
              if (that.sentences[k].id === id){
                that.sentences[k].chipClass = words > 0 ? 'upAnimation' : 'downAnimation'
              }
            }
          }, 900))
          this.timeouts.push(setTimeout(function(){
            for (let k in that.sentences) {
              if (that.sentences[k].id === id){
                that.$delete(that.sentences, k)
              }
            }
          }, 1500))
        }
      }
      window.scroll(0, 0)
    },
    sendCaptions: function(){
      this.captions = this.captions.replace(/(?:\r\n|\r|\n)/g, '');
      if (this.captions.length < 1 || this.captions.length > 500 || this.correcting){
        return;
      }
      if(!checkToken(this.$store,this.$router)){
        return
      }
      this.countSentences++
      window.scroll(0, 0)
      this.$refs.textCaptions.focus()
      this.$root.$emit('sendCaptionsPlayVerse', { captions : this.captions , id : this.lastIdSentence } )
      this.sentences.push( { id : this.lastIdSentence , text : this.captions , class : "beatAnimation" , color: 'purple' , chipClass : '' } )
      this.lastIdSentence++
      this.captions = ''
    },
    disableSend: function(){
      this.correcting = true
    }
  },
  beforeDestroy: function(){
    this.$root.$off('onHelp')
    this.$root.$off('offHelp')
    this.$root.$off('onPlayAudio')
    this.$root.$off('onFinishAudio')
    for (let i in this.timeouts) {
      if (this.timeouts[i]){
        clearTimeout(this.timeouts[i])
      }
    }
  }
};
</script>

<style>
  
  @keyframes beat {
    from { transform: none; }
    50% { transform: scale(1.4); }
    to { transform: none; }
  }

  .beatAnimation {
    animation: beat .5s infinite;
    transform-origin: center;
  }

  @keyframes up {
      0% { transform: translateY(0); opacity: 1; }
      100% { transform: translateY(-30px); opacity: 0;}
  }

  .upAnimation {
    animation: up 1s;
  }

  @keyframes down {
      0% { transform: translateY(0); opacity: 1; }
      100% { transform: translateY(30px); opacity: 0; }
  }

  .downAnimation {
    animation: down 1s;
  }

  @keyframes vibration {
    10%, 90% {
      transform: translate3d(-2px, 0, 0);
    }
    30%, 75% {
      transform: translate3d(1px, 0, 0);
    }
    40%, 50%, 60% {
      transform: translate3d(-3px, 0, 0);
    }
    25%, 55% {
      transform: translate3d(3px, 0, 0);
    }
  }

  .shakeAnimation {
    animation: vibration 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }

</style>